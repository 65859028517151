<template>
  <CommonPages id="novel-detail" classs="novel-detail">
    <div class="book-nav-row">
      <div class="book-nav-item cursor" @click="toggleNav()">
        {{ currentChapter?.chapter || defaultChapter?.chapter || 'Select Chapter' }}
        <img v-show="showNav" src="@/assets/arrow.png" alt="" class="nav-arrow">
        <img v-show="!showNav" class="nav-arrow rotate" src="@/assets/arrow.png" alt="">
      </div>
    </div>

    <!-- <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_1"
      ref="ads-home-insert-1" class="ads-insert-max pc_padding m-t-40" :ads="adsensConfig?.detail_1"
      :showDebug="showDebug" /> -->

    <div class="book-detail-row pc_padding m_set_width">
      <div v-show="showNav" class="book-list-nav-row">
        <div class="book-list-nav-left">
          <div v-for="(chap, i) in currentPartChapters" :key="i" class="book-list-nav-item cursor"
            :class="{'active': currentChapter?.contentId === chap.contentId}"
            @click="chooseChapter(chap)">
            {{ chap.chapter }}
          </div>

          <div class="other-book">
            <div v-for="(otherChapter, i) in otherParts" :key="i" class="other-book-item cursor"
              @click="choosePart(otherChapter)">
              {{ otherChapter.chapter }}
            </div>
          </div>
        </div>
      </div>

      <div class="chapter-container">
        <div class="chapter-catalogue">
          <div class="chapter-catalogue-point" />
          {{ currentChapter?.chapter || defaultChapter?.chapter || 'Loading...' }}
        </div>

        <!-- <AdComponent
          v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_1"
          ref="ads-detail-insert-1" class="ads-insert-max pc_padding" :ads="adsensConfig?.detail_1"
          :showDebug="showDebug" />
        <AdComponent
          v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_2"
          ref="ads-detail-insert-2" class="ads-insert-max pc_padding m-t-300"
          :ads="adsensConfig?.detail_2" :showDebug="showDebug" /> -->

        <div v-if="chapterContent" class="chapter-row">
          <div v-html="chapterContent" />
        </div>

        <!-- PC端章节导航 -->
        <div v-if="currentPartChapters && deviceType != 'mobile'" class="chapter-nav-row">
          <div class="chapter-nav-item cursor"
            :class="{'active': currentChapter?.contentId !== currentPartChapters[0]?.contentId}"
            @click="gotoBeginning()">
            <img src="@/assets/chapter-arrow.png" alt="">
            Beginning of Chapter
          </div>
          <div class="chapter-nav-item cursor" :class="{'active': hasNextChapter}"
            @click="nextChapter()">
            Next Chapter
            <img src="@/assets/chapter-arrow-r.png" alt="">
          </div>
        </div>

        <!-- 移动端章节导航 -->
        <div v-if="currentPartChapters && deviceType == 'mobile'" class="chapter-nav-row mobile">
          <div class="chapter-nav-item cursor mobile" :class="{'active': chapterNumber > 1}"
            @click="prevChapter()">
            <img src="@/assets/chapter-arrow.png" alt="">
          </div>
          <div class="chapter-nav-item cursor mobile" :class="{'active': hasNextChapter}"
            @click="nextChapter()">
            <img src="@/assets/chapter-arrow-r.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/pc_common.vue'
import '@/css/novel-detail.scss';

export default {
  name: 'NovelDetail',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      showNav: true,
      activeIndex: null,
      bookInfo: null,
      bookname: null,
      chapterContent: null,
      currentChapter: null,
      hasInitBooksFromServer: false,
      novelId: null,
      currentPartChapters: [],
      otherParts: [],
      books: [],
      currentPart: '',
      part: '',
      chapter: '',
      defaultChapter: null,
    };
  },
  computed: {
    title () {
      return `Novles | Discover Your Next Favorite Novel`
    },
    description () {
      return `Explore a vast collection of novels across all genres. Start your literary journey today!`
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
      'parts',
      'author'
    ]),
    chapterNumber () {
      return parseInt(this.$route.params.chapters) || 1;
    },
    hasNextChapter () {
      return this.currentPartChapters &&
        this.chapterNumber < this.currentPartChapters.length;
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    const { novelId } = this.$route.params;
    console.log(`novelId: ${novelId}`);

    if (this.deviceType == 'mobile') {
      this.showNav = false
    }

    this.novelId = novelId;
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)

    // 检查服务端数据是否有效
    const hasValidServerData = this.$root.$options?.context?.hasValidServerData
    console.log('Server data validation:', hasValidServerData)

    // 获取章节列表数据
    if (!this.hasInitBooksFromServer || !hasValidServerData) {
      try {
        await this.initPart();
      } catch (err) {
        if (err.message !== 'cancel') {
          console.error('Failed to fetch data:', err);
        }
      }
    }
  },
  methods: {
    toggleNav () {
      this.showNav = !this.showNav
    },
    formatName (name) {
      if (!name) return '';
      return name.split('-').join(' ');
    },
    transformName (name) {
      return name.toLowerCase()
        .split(' ')
        .join('-');
    },
    gotoBeginning () {
      if (this.currentPartChapters && this.currentPartChapters.length > 0) {
        this.initDetail(this.currentPartChapters[0].contentId);
      }
    },
    async initPart () {
      console.log('Fetching parts list');
      const params = {
        novelId: this.novelId,
      };

      try {
        const res = await this.$global.server.initPart(params);
        console.log('Parts list:', res);
        if (res?.data) {
          // 保存所有数据
          this.books = res.data.partsAndChapters || [];
          this.author = res.data.author;
          this.total = res.data.total;

          // 将数据保存到 store
          this.$store.commit('SET_PARTS', this.books);
          this.$store.commit('SET_AUTHOR', this.author);

          // 获取第一章节的内容
          if (this.books.length > 0 && this.books[0].chapters.length > 0) {
            const firstChapter = this.books[0].chapters[0];
            this.defaultChapter = firstChapter;
            this.currentChapter = firstChapter;
            await this.initDetail(firstChapter.contentId);

            // 设置当前章节列表
            this.currentPartChapters = this.books[0].chapters;
            // 获取其他parts
            this.otherParts = this.books.slice(1).map(book => book.chapters[0] || null).filter(Boolean);
          }
        }
      } catch (err) {
        console.error('Error fetching parts:', err);
      }
    },
    async initDetail (contentId) {
      const params = {
        type: this.domainConfig['IAmType'],
        contentId: contentId,
      }

      try {
        const res = await this.$global.server.initDetail(params)
        if (res?.data) {
          this.chapterContent = res.data;
          // 更新当前章节信息
          this.currentChapter = this.findChapterByContentId(contentId);
        }
      } catch (err) {
        console.error('Failed to fetch detail:', err)
      }
    },
    findChapterByContentId (contentId) {
      for (const book of this.books) {
        const chapter = book.chapters.find(ch => ch.contentId === contentId);
        if (chapter) return chapter;
      }
      return null;
    },
    chooseChapter (chapter) {
      this.initDetail(chapter.contentId);
    },
    choosePart (chapter) {
      const targetBook = this.books.find(book =>
        book.chapters.some(ch => ch.contentId === chapter.contentId)
      );
      if (targetBook && targetBook.chapters.length > 0) {
        this.currentPartChapters = targetBook.chapters;
        this.initDetail(chapter.contentId);
      }
    },
    nextChapter () {
      const currentPartIndex = this.books.findIndex(book =>
        book.chapters.some(ch => ch.contentId === this.currentChapter.contentId)
      );

      if (currentPartIndex === -1) return;

      const currentPart = this.books[currentPartIndex];
      const currentChapterIndex = currentPart.chapters.findIndex(ch =>
        ch.contentId === this.currentChapter.contentId
      );

      if (currentChapterIndex < currentPart.chapters.length - 1) {
        // 当前部分还有下一章
        this.initDetail(currentPart.chapters[currentChapterIndex + 1].contentId);
      } else if (currentPartIndex < this.books.length - 1) {
        // 切换到下一部分的第一章
        const nextPart = this.books[currentPartIndex + 1];
        this.currentPartChapters = nextPart.chapters;
        this.initDetail(nextPart.chapters[0].contentId);
      }
    },
    prevChapter () {
      const currentPartIndex = this.books.findIndex(book =>
        book.chapters.some(ch => ch.contentId === this.currentChapter.contentId)
      );

      if (currentPartIndex === -1) {
        console.error('当前章节未找到');
        return;
      }

      const currentPart = this.books[currentPartIndex];
      const currentChapterIndex = currentPart.chapters.findIndex(ch => ch.contentId === this.currentChapter.contentId);

      if (currentChapterIndex > 0) {
        // 当前部分的上一章
        const prevChapter = currentPart.chapters[currentChapterIndex - 1];
        this.currentPartChapters = currentPart.chapters;
        this.initDetail(prevChapter.contentId);
      } else if (currentPartIndex > 0) {
        // 上一部分的最后一章
        const prevPart = this.books[currentPartIndex - 1];
        const lastChapter = prevPart.chapters[prevPart.chapters.length - 1];
        this.currentPartChapters = prevPart.chapters;
        this.initDetail(lastChapter.contentId);
      } else {
        console.log('已经是第一章了');
      }
    }
  }
};
</script> 